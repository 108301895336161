import { combineReducers } from "redux";
import { Action, getType } from "typesafe-actions";

import { vuetApi } from "./services/api/api";
import { logOut } from "./slices/auth/actions";
import { authReducer } from "./slices/auth/reducer";
import { AuthState } from "./slices/auth/types";


export type EntireState = {
  authentication: AuthState;
  [vuetApi.reducerPath]: any; // TODO -think about this
} | undefined

const appReducer = combineReducers({
  authentication: authReducer,
  [vuetApi.reducerPath]: vuetApi.reducer,
});

const rootReducer = (state: EntireState, action: Action) => {
  if (action.type === getType(logOut)) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
