import { vuetApi } from "./api";

export type User = {
  name: string;
  email: string;
  id: number;
  is_admin: boolean;
  is_charity_admin: boolean;
  is_retailer_admin: boolean;
  is_end_user: boolean;
}

const subscriptionsApi = vuetApi.injectEndpoints({
  endpoints: builder => ({
    createSubscription: builder.mutation<{ url: string }, { type: "MONTHLY" | "YEARLY"; userId: number; isFamily: boolean; }>({
      query: body => ({
        method: "POST",
        url: "subscriptions/create-checkout-session/",
        body: { type: body.type, user_id: body.userId, is_family: body.isFamily }
      }),
    })
  })
});

export default subscriptionsApi;

export const {
  useCreateSubscriptionMutation
} = subscriptionsApi;
