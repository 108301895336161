import { useSelector } from "react-redux";

import { useGetUserDetailsQuery, useGetUserFullDetailsQuery } from "../reduxStore/services/api/users";
import { selectAccessToken } from "../reduxStore/slices/auth/selectors";

export default function useGetUserFullDetails() {
  const accessToken = useSelector(selectAccessToken);
  const { data: userDetails } = useGetUserDetailsQuery(accessToken);

  const userId = userDetails ? userDetails.user_id : -1;
  const userFullDetails = useGetUserFullDetailsQuery(userId);

  return userFullDetails;
}
