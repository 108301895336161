import { AuthDetails, SecureUpdateUserRequest, UserFullResponse } from "../../../types/users";

import { vuetApi } from "./api";

export type User = {
  name: string;
  email: string;
  id: number;
  is_admin: boolean;
  is_charity_admin: boolean;
  is_retailer_admin: boolean;
  is_end_user: boolean;
}

export type LoginRequest = { email?: string; phone_number?: string; password: string; }

const userApi = vuetApi.injectEndpoints({
  endpoints: builder => ({
    getUserFullDetails: builder.query<UserFullResponse, number>({
      query: user_id => ({
        url: `core/user/${user_id}/`
      }),
      providesTags: [ "User" ]
    }),
    getUserDetails: builder.query<AuthDetails, string>({
      query: accessToken => ({
        url: "auth/details/"
      }),
      providesTags: [ "User" ]
    }),
    loginWithUsernameAndPassword: builder.mutation<{ refresh: string; access: string; }, LoginRequest>({
      query: body => ({
        method: "POST",
        url: "auth/token/",
        body
      }),
      invalidatesTags: [ "User" ]
    }),
    verifyToken: builder.mutation<{ res?: any }, string>({
      query: token => ({
        method: "POST",
        url: "auth/token/verify/",
        body: { token }
      }),
      invalidatesTags: [ "User" ]
    }),
    refreshToken: builder.mutation<any, string>({
      query: token => ({
        method: "POST",
        url: "auth/token/refresh/",
        body: { refresh: token }
      }),
      invalidatesTags: [ "User" ]
    }),
    secureUpdateUserDetails: builder.mutation<
      UserFullResponse,
      SecureUpdateUserRequest
    >({
      query: body => ({
        url: `core/user-secure-update/${body.user_id}/`,
        method: "PATCH",
        body
      }),
      invalidatesTags: [ "User" ]
    }),
  })
});

export default userApi;

export const {
  useGetUserDetailsQuery,
  useGetUserFullDetailsQuery,
  useLoginWithUsernameAndPasswordMutation,
  useVerifyTokenMutation,
  useRefreshTokenMutation,
  useSecureUpdateUserDetailsMutation
} = userApi;
