import { useSelector } from "react-redux";

import { useGetUserDetailsQuery } from "../reduxStore/services/api/users";
import { selectAccessToken } from "../reduxStore/slices/auth/selectors";

export default function useGetUserDetailsForToken () {
  const accessToken = useSelector(selectAccessToken);
  const userDetails = useGetUserDetailsQuery(accessToken);

  if (!accessToken) {
    return { data: null, isLoading: false };
  }

  return userDetails;
}