export type Props = React.HTMLProps<HTMLInputElement> & {
  label: string;
  labelClassName?: string;
  inputClassName?: string;
  modalInfo?: React.ReactNode;
}
const InputPair = ({
  label,
  labelClassName,
  inputClassName,
  className,
  modalInfo,
  ...otherProps
}: Props) => {
  return <label className={`flex flex-col sm:flex-row justify-start sm:items-center ${className || ""}`}>
    <div className={labelClassName || ""}>{label}</div>
    <input
      className={inputClassName || ""}
      { ...otherProps }
    />
  </label>;
};

export default InputPair;