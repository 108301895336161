const Title = ( { text }: { text: string }) => {
  return <h1 className="font-bold text-xl mb-4">{text}</h1>;
};

const SubTitle = ( { text }: { text: string }) => {
  return <h1 className="font-bold text-lg mb-2 mt-8">{text}</h1>;
};

const TitledListItem = ({ title, text }: {title: string, text: string}) => {
  return <li className="mt-2"><b>{title}</b> {text}</li>;
};

export default function Privacy () {
  return <div className="flex flex-row justify-center px-40 mt-4">
    <div className="pb-16 text-left">
      <Title text="Vuet - Privacy Statement" />
      <SubTitle text="What Personal Information Do We Collect?"/>
      <p>
      When you visit our app, we may collect the following personal information from or about you:
        <TitledListItem title="Online Identifiers:" text="IP address, advertising ID, unique device ID, and other information about your device, and internal and third-party IDs that have been assigned to you." />
        <TitledListItem title="Location Information:" text="Limited to general information about the geographic region in which you are located, in order to provide the appropriate service." />
        <TitledListItem title="Internet Activity Information:" text="Information about your use of VUET, including the products you purchase, web pages, content and advertisements you view and links you click in the e-mails we send to you." />
        <TitledListItem title="Inferences about Your Interests, Preferences, and Other Behavioural Data:" text="Hobbies and interests. When you sign up for a newsletter, register for our services, create a calendar event or a list, or ask us for additional information, we may also collect:" />
        <TitledListItem title="Contact Information of Others:" text="Contact information of other individuals (when you use the sharing and referral tools available within some of our services to forward content or offers to your friends and associates)." />
        <TitledListItem title="Account/Registration Information:" text="Username and password, e-mail address, and other information stored in your account (when you provide that information to us)." />
        <TitledListItem title="Your VUET Content:" text="Personal Information that you add when using any of our features, including the VUET calendar or a third party calendar (such as Google) that you have linked to the VUET service, appointments, events, lists (shopping and to do), photos, journal, and contacts information. For example, you may choose to add information about your medical or legal appointments, children's or school events, medications, book or video titles, and personal products; financial, medical, and children's information; and personal reminders). You may also choose to add contact information such as the first and last name, company, phone number, e-mail address, birthday, anniversary, and categorisations of the contact (such as family, doctor, emergency, and holiday cards)." />
        <TitledListItem title="Payment Information:" text="We store personal information necessary to process your payment if you provide us with any payment information related to the Service, e.g. if you upgrade to VUET Premium from our website, we will ask for your credit card information, which will be processed directly by a service provider. We do not store your credit card number. If you upgrade to VUET Premium from an iOS or Android app, you will pay for the upgrade directly through Apple or Google, respectively, and we will not have access to your credit card information." />
        <TitledListItem title="Employment and Professional Information, and Education Information:" text="When you send your resume to us. When you interact with VUET through voice assistants such as by using a “skill” on an Amazon Alexa or by using an “action” on a Google Home device, we do not collect your voice or audio information, but we will know what you are “searching” for. You may have the option to provide personal information such as your e-mail address or phone number to receive additional information from us." />
      </p>
      <SubTitle text="Where/How Do We Collect Personal Information?"/>
      <p>
      We collect personal information about you from a number of sources:
        <TitledListItem title="From you:" text="We collect your contact, registration/account information, payment information, and any other personal information when you provide it to us. When you make a purchase on the app, we also retain a record of your purchase and order history." />
        <TitledListItem title="From Your Device:" text="" />
        <ol className="ml-8">
          <TitledListItem title="Cookies:" text="A 'cookie' is a tiny file that's stored on your computer. It contains a unique identifier, as well as the address and other information about the website you are visiting. Cookies help us know whether you are a returning visitor to our service. For example, although you may not log-in when you visit VUET, we can use cookies to recognise that you have visited VUET in the past. By using cookies, we are able to understand what is of interest to our customers and ensure the content and services we offer are relevant and compelling when you visit VUET. In some cases, we may use the personal information we collect from cookies to deliver relevant and compelling content to you when you visit VUET." />
          <TitledListItem title="Web Beacons, Pixel Tags, and Other Tracking Technologies:" text="Web beacons, pixel tags and other tracking technologies are small strings of code placed on a web page or in an e-mail or advertisement. These technologies allow your browser to recognise and store certain types of information such as cookies previously set, the time and date that you viewed the page with the beacon, and a description of that page." />
          <TitledListItem title="Device Fingerprinting:" text="Device fingerprinting is the process of analysing and combining sets of information elements from your device's browser, such as JavaScript objects and installed fonts, in order to create a 'fingerprint' of your device and uniquely identify your device and apps." />
          <TitledListItem title="App Technologies:" text="There are a variety of tracking technologies that may be included in our apps, and these are not browser-based like cookies and cannot be controlled by browser settings. For example, our apps may include third-party SDKs, which are codes that send information about your use to a server, and are in effect the app version of a pixel. These SDKs allow us to track our conversions, bring you advertising both on and off VUET, and provide you with additional functionality." />
          <TitledListItem title="Location-Identifying Technologies:" text="We use your IP address to determine general information about your location, such as the geographic region or country in which you are located, in order to provide the appropriate service." />
        </ol>
        <p className="mt-2">
        Some personal information about your use of VUET and third-party services may be collected using tracking technologies across time and services. VUET and third parties may use this information for purposes such as to distinguish between the different devices that you use, and deliver relevant ads and content to you on VUET and third-party services.
        </p>
      </p>
      <SubTitle text="Can You Opt Out?"/>
      <p>You can opt out in many instances. There are some cookies that we need in order to operate VUET. If you deleted those cookies, VUET would no longer function (as an example, if we can&apos;t remember what account you belong to, we cannot show you your information on VUET). You can always decide to opt-out of or delete the third party cookies (cookies set by advertisers and data partners) on VUET. Keep in mind, if you block cookies, you may not be able to view or access some of the features of VUET. Visit our section on your privacy choices for more information on how to opt-out.</p>
      <p className="mt-2">From Others:</p>
      <TitledListItem title="Service Providers:" text="We work with service providers who collect personal information on our behalf in order to provide services to us, such as hosting our websites, processing our credit card transactions, verifying your identity, and other activities needed to help us provide our content and services to you. We only allow our service providers to use your personal information in connection with the services they provide us." />
      <TitledListItem title="Data Partners:" text="We work with third-party data partners that share personal information with us, including your demographic information, internet activity information, purchasing information, and interests, preferences, and other behavioural data, which help us understand you better and ensure the content and services we offer are relevant and compelling." />

      <SubTitle text="How Do We Use Personal Information?"/>
      <p>
        We and the service providers working on our behalf use personal information collected about you to:
        <ol>
          <TitledListItem title="Personalise Your Experience on VUET, Including the Ads Displayed to You:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="If you are a VUET Premium subscriber, your experience on the VUET app will be ad-free." />
          <TitledListItem title="" text="Customise the content you see when you use VUET, the websites of other companies, and in our e-mail communications." />
          <TitledListItem title="" text="Remember your browser settings and any preferences you set while using VUET." />
          <TitledListItem title="" text="Deliver relevant interest-based advertising to you." />
        </div>
        <ol>
          <TitledListItem title="Provide Offers and Promotions to You:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Allow you to participate in features such as contests and other co-sponsored promotions." />
        </div>
        <ol>
          <TitledListItem title="Contact You:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Develop and send you direct marketing, including advertisements and communications about our and third-party products, offers, promotions, rewards, events, and services." />
          <TitledListItem title="" text="Send you technical notices, updates, security alerts, information regarding changes to our policies, and support and administrative messages." />
        </div>
        <ol>
          <TitledListItem title="Create and Manage Your Account:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Create and manage your account, including providing you with access to your account." />
        </div>
        <ol>
          <TitledListItem title="Provide and Improve VUET:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Provide you with the ability to use features of VUET, including but not limited to the Calendar, To Do List, Tasks, and surveys." />
        </div>
        <ol>
          <TitledListItem title="Analyse, operate, and improve VUET (including repairing errors or debugging) or develop new ones:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Measure and analyse visitor traffic and usage patterns and improve the quality and effectiveness of VUET, features, and related communications." />
        </div>
        <ol>
          <TitledListItem title="Research and Analysis:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Monitor and analyse trends, usage, and activities." />
          <TitledListItem title="" text="Conduct research, including focus groups and surveys." />
        </div>
        <ol>
          <TitledListItem title="Security and Fraud Prevention:" text="" />
        </ol>
        <div className="ml-8">
          <TitledListItem title="" text="Enforce our legal rights or comply with the law." />
          <TitledListItem title="" text="Prevent potentially prohibited or illegal activities, comply with applicable law, and enforce our Terms of Use." />
        </div>
        <p className="mt-2">
        We may use your information for any other purposes disclosed to you at the time we collect your personal information, provided we have obtained the required consent.
        </p>
      </p>

      <SubTitle text="Who Do We Share Personal Information With?"/>
      <p>We may share your personal information with our service providers, promotional partners, and third-party data partners.</p>
      <TitledListItem title="Service Providers:" text="We share personal information with our service providers in connection with their work on our behalf. Service providers assist us with services such as payment processing, data analytics, marketing and promotional services, promotions, website hosting, and technical support. Service providers will access your personal information only to perform tasks on our behalf, in compliance with this Privacy Statement and are prohibited from using your personal information for any purpose other than to provide this assistance, although we may permit them to use aggregate information which does not identify you or de-identified data for other purposes." />
      <TitledListItem title="Promotional Partners:" text="Our promotions may be jointly sponsored or offered by third parties. If you voluntarily choose to enter a promotion, we may share your personal information with third parties as set forth in the official rules that govern the promotion as well as for administrative purposes and as required by law (e.g. on a list of winners)." />
      <TitledListItem title="Data Partners:" text="We provide certain third-party partners with personal information about users for purposes of tailoring advertisements (such as third-party offers related to your activities or priorities), measuring and improving our service and advertising effectiveness, and enabling other enhancements. We may also offer you the ability to link your VUET account to a Third-Party Service in order to retrieve certain data about your account on that service. For example, if you link your VUET account to your Google calendar, the linking may allow us to obtain calendar content. For more information about how these platforms handle your information, please refer to their respective privacy policies and terms of use." />
      <TitledListItem title="Third-Party Features:" text="We may allow you to connect VUET to a third-party service or offer VUET through a third party service ('Third-Party Feature'). If you use a Third-Party Feature, both VUET and the applicable third party may have access to and use personal information associated with your use of the Third-Party Feature, and you should carefully review the third party's privacy statement and terms of use. Some examples of Third-Party Features include the following:" />
      <div className="ml-8">
        <TitledListItem title="Brand Pages:" text="We may offer our content on social networks such as Facebook and Twitter. Any personal information you provide to us when you engage with our content (such as through our brand page) is treated in accordance with this Privacy Statement. Also, if you publicly reference VUET on a third-party service (e.g. by using a hashtag associated with VUET in a tweet or post), we may use your reference on or in connection with our service." />
        <TitledListItem title="Platform Linking:" text="We may offer you the ability to link your VUET account to a third-party service in order to retrieve certain data about your account on that service. For example, if you link your VUET account to your Google calendar, the linking may allow us to obtain personal information such as your username, e-mail address, and calendar content. For more information about how these platforms handle your personal information, please refer to their respective privacy policies and terms of use." />
      </div>
      <TitledListItem title="Legal Compliance, Business Transfers, and Other Disclosures:" text="We may disclose your personal information to third parties if we determine that such disclosure is necessary to (a) comply with the law or in response to a law enforcement agency's request; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of VUET or our users; or (d) protect VUET’s property rights. Over time, we may reorganise or transfer various assets and lines of business. We reserve the right to disclose or transfer any information we collect to third parties in connection with any proposed or actual purchase, sale, lease, merger, foreclosure, liquidation, amalgamation or any other type of acquisition, disposal, transfer, conveyance or financing of all or any portion of VUET." />

      <SubTitle text="How Can You Control Our Use of Your Personal Information?"/>
      <ol>
        <TitledListItem title="What Rights Do You Have?" text="" />
      </ol>
      <TitledListItem title="Right to Know:" text="You have the right to ask us to tell you what personal information about you we collect, use, disclose, and sell." />
      <TitledListItem title="Right to Request Deletion:" text="You have the right to ask us to delete the personal information we collect or maintain about you." />
      <TitledListItem title="Right to Be Free from Discrimination:" text="You have the right to not be discriminated against for exercising these rights." />
      <TitledListItem title="Right to Access or Correct Your Personal Information:" text="You have the right to request access to and to edit certain personal information that you have provided to us through VUET." />
      <ol>
        <TitledListItem title="If you are in the U.S. and wish to exercise your rights:" text="" />
      </ol>
      <TitledListItem title="Information and Deletion Requests:" text="To exercise your right for information on our data collection practices on VUET or request deletion of the personal information on VUET, contact info@vuet.app. You will need to login to your account in order to verify your request." />
      <TitledListItem title="Authorised Agents:" text="You can also designate an authorised agent to make a request on your behalf. If you use an authorised agent, please include written permission that you have designated that agent to make the request, or proof of the agent‘s power of attorney. We may follow up with you to verify your identity before processing your authorised agent’s request." />
      <SubTitle text="What Other Choices Do You Have?"/>
      <ol>
        <TitledListItem title="Communications" text="" />
      </ol>
      <p>There are a few ways to opt-out of communications, including those that are for marketing purposes.</p>
      <TitledListItem title="Push Notifications" text="If you have opted-in to receive push notifications on your device, you can adjust how you receive notifications in your device settings. If you'd like to opt-out of receiving push notifications, you may change your device settings, adjust your notification preferences in your family settings, or uninstall the app." />
      <TitledListItem title="E-mail" text="You can opt-out of receiving promotional e-mails from us at any time by following the instructions as provided in e-mails to click on the unsubscribe link. You can opt-out of receiving non-promotional service e-mails such as reminders and agendas by visiting your Family Settings. Please note that you cannot opt-out of certain non-promotional e-mails, such as those about your account, transactions, servicing, or VUET's ongoing business relations." />
      <TitledListItem title="Unlinking Your Accounts" text="If you have linked your VUET account with certain third-party services such as your Google calendar, you may unlink your accounts at any time by visiting your VUET account settings. Please note that unlinking your accounts will not affect any personal information previously shared through the linking. VUET is not responsible for third-party data partners' data practices, and we recommend that you carefully review their privacy policies and terms of use." />
      <ol>
        <TitledListItem title="Opting Out of Cookies & Other Technologies" text="" />
      </ol>
      <p>There are a few ways to opt out of or delete cookies:</p>
      <TitledListItem title="On Your Browser (Chrome, Safari, etc.):" text="The settings of the browser you use give you an option to accept or reject most cookies, or to notify you when a cookie is set. (Each browser is different, so check the 'Help' menu of your browser to learn how to change your cookie preferences.) Please be aware that certain browsers cannot block or delete so-called Flash cookies, which use a feature of the Adobe Flash video player to store information on your computer. For information about how to delete Flash cookies, please visit the Adobe website. This will opt you out of cookies on the browser you are using. If you use multiple browsers, you will need to take this step for all relevant browsers. Please note that denying all or certain cookies may result in a less personalised experience of some of the services offered on the website." />
      <TitledListItem title="Opting Out on a Mobile Device:" text="Depending on your device and operating system, you may be able to reset your mobile device's advertising identifier to limit this data collection or prevent the collection of usage data across unaffiliated applications for interest-based advertising purposes by accessing the privacy settings on your mobile device. Each operating system, iOS for Apple devices, Android for Android devices and Windows for Microsoft devices, provides its own instructions. Visit the privacy settings for your mobile device, or contact your platform operator for more information. You can stop all collection of information via an app by uninstalling the app." />
      <TitledListItem title="Opting Out of Interest-Based Advertising" text="If you are a VUET Premium subscriber, your experience on the VUET app will be ad-free." />
      <p className="mt-2">We follow the Digital Advertising Alliance&apos;s (DAA) Self-Regulatory Principles for Online Behavioural advertising.</p>
      <TitledListItem title="" text="To opt-out of our use of your website viewing data to serve interest-based advertising on third party websites and e-mail newsletters, visit the DAA's Consumer Choice Page." />
      <TitledListItem title="" text="You can turn off personalised ads on our web application at any time by clicking the relevant permission in your Settings." />
      <TitledListItem title="" text="You can turn off personalised ads on your mobile device:" />
      <div className="ml-8">
        <TitledListItem title="" text="On an Android device, in your Settings, select Google, select Ads, and enable the 'Opt out of Ads Personalisation' setting." />
        <TitledListItem title="" text="On an Apple device, in your Settings, select Privacy, select Advertising, and enable the 'Limit Ad Tracking' setting." />
      </div>
      <TitledListItem title="" text="Most of the third-party advertising and data companies we work with, including DoubleClick and Google, also subscribe to the DAA Principles and/or are members of the Network Advertising Initiative (NAI). To learn more about the information collection practices of NAI members and your related opt-out choices, visit the NAI website." />
      <TitledListItem title="" text="You must opt out separately from each computer or device and browser that you use to access VUET." />
      <ol>
        <TitledListItem title="Company-Specific Opt-Outs" text="" />
      </ol>
      <TitledListItem title="App Technologies:" text="You can reset your device advertising ID at any time through your device settings, which is designed to allow you to limit the use of information collected about you. Visit apple.com, for information on how to do this on Apple devices. For information on how to do this on Android devices, visit Google.com. You can stop all collection of information via an app by uninstalling the app." />
      <TitledListItem title="Google Analytics:" text="You can opt out of your data being used by Google Analytics through cookies by visiting and downloading the Google Analytics Opt-out Browser Add-on." />
      <TitledListItem title="Do Not Track:" text="We do not currently respond to browser do-not-track signals. Instead, we adhere to the standards set out in this Privacy Statement and offer the opt-out choices described above." />
      <p className="mt-2">Please be aware that if you disable or remove Tracking Technologies some parts of VUET may not function correctly.</p>

      <SubTitle text="Children's Privacy"/>
      <p>
        We do not knowingly allow anyone under the age of 16 to provide us with any personal information. In order to register and create a VUET account, you must be 18 years of age or older.
        Children should always get permission from their parents before sharing any personal information about themselves over the Internet.
        If you&apos;re under 16, please do not register or provide us with any personal information (such as your name, e-mail address, or phone number).
        If you are a VUET user, you may, at any time, direct VUET to delete all personal information regarding you or your family that you have provided or that has been collected from your family.
        To do so, please contact us.
      </p>
      <SubTitle text="Additional Privacy Information"/>
      <TitledListItem title="Contests and Other Co-Sponsored Promotions:" text="From time to time, we may offer contests, sweepstakes, or other promotions. Participation in these promotions may require registration to VUET. We may share your registration information with co-sponsors or other third parties involved in the promotion that we identify in the rules or entry materials. We don't control these third parties' privacy practices, and our Privacy Statement does not apply to their collection and use of your personal information. We may also share some of your entry information with third parties or the public in connection with the administration of the promotion, such as winner selection and prise fulfilment, and as permitted by the promotion’s official rules, such as on a list of winners." />
      <TitledListItem title="Security, Retention, and Storage of Information:" text="We use commercially reasonable procedures to protect the personal information that we collect. However, we cannot guarantee the security of our databases, nor can we guarantee that personal information you supply won't be intercepted while being transmitted to us over the Internet. Please see our Terms of Use for more information related to posting materials on VUET including our use of such materials." />
      <TitledListItem title="Location of Your Data:" text="VUET is a U.K. company and your personal information will be transferred to, processed, stored and accessed by us, VUET, and service providers in the United Kingdom and in other jurisdictions where we or they operate, which may not provide you with the same level of privacy protection as you are entitled to in your jurisdiction. When you visit VUET, you consent to the processing of your personal information in the United Kingdom and other jurisdictions as described here." />
      <TitledListItem title="EU-U.S. Privacy Shield:" text="VUET adheres to the principles of the EU-U.S. Privacy Shield Framework (“Privacy Shield Principles”) as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union (“EU”) to the United States; however. However, we do not rely on the EU-U.S. Privacy Shield Framework when we transfer or receive personal information transferred from the EU. If there is any conflict between the terms in this Privacy Policy Statement and the Privacy Shield Principles, the Privacy Shield Principles will govern. To learn more about the Privacy Shield program, please visit the Privacy Shield website." />
      <p className="mt-4">
      VUET is responsible for the processing of personal information it receives under the EU-U.S. Privacy Shield Framework, or subsequently transfers to a third party acting as an agent on its behalf. VUET complies with the Privacy Shield Principles for all onward transfers from the EU and European Economic Area (“EEA”), including onward transfer liability provisions.
      </p>
      <p className="mt-4">
        In certain situations, VUET may be required to disclose personal information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.
      </p>
      <p className="mt-4">
        In compliance with the Privacy Shield Principles, VUET commits to resolve complaints about our collection or use of your personal information. EU individuals with inquiries or complaints should first contact us at info@vuet.app.
      </p>
      <p className="mt-4">
        VUET has further committed to refer unresolved Privacy Shield complaints to an established alternative dispute resolution provider. If you do not receive timely acknowledgment of your complaint from us, or if we have not resolved your complaint, please contact us to file a complaint. If the arbitrator finds that you cannot afford to pay the arbitrator&apos;s filing, administrative, hearing, and/or other fees and you cannot obtain a waiver from the arbitrator, VUET will pay them for you.
      </p>
      <TitledListItem title="Changes to Our Privacy Statement:" text="Digital technology is rapidly evolving. If we decide to change our Privacy Statement in the future, we'll post the changes here and indicate at the top of the page the last date on which it was updated. Unless otherwise noted, all changes will be effective when posted." />
      <SubTitle text="Rights for Our California Residents"/>
      <p>This section contains information on the personal information we collect about residents of California (our &apos;California Consumers&apos;), including the sources of that information, how that informed is used, who it is shared with, and the rights that California Consumers have under California law. Our collection of your information, our data sharing practices, and your privacy rights are discussed below.</p>
      <SubTitle text="I. Our Collection of Your Information" />
      <SubTitle text="What Personal Information Do We Collect?" />
      <TitledListItem title="Account/Registration Information:" text="(such as username and password, e-mail address, and other information stored in your account)" />
      <TitledListItem title="Contact Information of Others:" text="(contact information of other individuals, e.g. when you use the sharing and referral tools available within VUET to forward content or offers to your friends and associates)" />
      <TitledListItem title="Online Identifiers" text="(such as IP address, internal and third-party identifiers, advertising identifiers, and device information such as unique device ID)" />
      <TitledListItem title="Your VUET Content" text="(content provided by you using VUET features such as calendar, lists, and others)" />
      <TitledListItem title="Location Information" text="(limited to general information about the geographic region in which you are located in order to provide the appropriate service)" />
      <TitledListItem title="Internet Activity Information" text="(information on how you interact with VUET, e.g. which features of the services you use or ads you click on)" />
      <TitledListItem title="Interests, Preferences, and Other Behavioural Data" text="(such as hobbies and interests, e.g. your favourite foods, purchases, or recipes, and consuming tendencies)" />
      <TitledListItem title="Payment Information" text="(such as name, billing address, and credit card information, e.g. when making a purchase)" />
      <TitledListItem title="Employment and Professional Information, and Education Information" text="(when you send your resume to us)" />
      <SubTitle text="What Are Our Sources of Personal Information?" />
      <TitledListItem title="You" text="(when you register on VUET, subscribe to one of our publications, or otherwise submit personal information to us)" />
      <TitledListItem title="Our data partners" text="(these are companies that give us personal information about you to help us and our advertisers understand who you are and what you may be interested in)" />
      <TitledListItem title="Pixels, cookies, and other tracking technologies" text="(these are used to collect personal information about you when you visit VUET online)" />
      <TitledListItem title="Location tracking technologies" text="(IP Address)" />
      <SubTitle text="How Do We Use Personal Information?" />
      <ol>
        <TitledListItem title="To Provide VUET to You:" text=""/>
      </ol>
      <TitledListItem title="To create and manage your account:" text="(when you register on VUET, and provide you with your membership benefits)"/>
      <TitledListItem title="To contact you:" text="(to deliver our marketing messages, technical notices, updates, security alerts, information regarding changes to our policies, and other communications to you)"/>
      <TitledListItem title="To process your payment:" text="(e.g. if you upgrade to VUET Premium from our website)"/>
      <TitledListItem title="To facilitate your use of VUET" text=""/>
      <ol>
        <TitledListItem title="For Advertising and Marketing:" text=""/>
      </ol>
      <TitledListItem title="To serve targeted ads to you:" text="(to help us, our advertisers, and our advertising networks and platforms to identity the best ads to display to you on VUET)."/>
      <TitledListItem title="To help us identify you and understand your interests and preferences:" text="so that we can customise your experience on VUET (including the content and advertisements displayed to you)"/>
      <TitledListItem title="To administer contests/promotions you enter" text=""/>
      <ol>
        <TitledListItem title="For Auditing Our Interactions:" text=""/>
      </ol>
      <TitledListItem title="To help us measure the effectiveness of the ads on VUET" text="including to understand whether you click on the ads on VUET and whether you buy the products/services advertised"/>
      <TitledListItem title="To conduct research/market analysis" text=""/>
      <ol>
        <TitledListItem title="For Our and Your Security:" text=""/>
      </ol>
      <TitledListItem title="To prevent fraud" text="including verifying your identity"/>
      <TitledListItem title="To detect security incidents" text=""/>
      <SubTitle text="II. Our Data Sharing Practices" />
      <p>We share the personal information below with our service providers. Our service providers help us send our newsletters, reminders, and notifications to you and provide advertising and marketing services, and for analytics, and other activities that support our business. Service providers only use the personal information we share with them to provide these services to us.</p>
      <p className="mt-4">
        <b>Below are the categories of personal information we disclose to our service providers:</b>
      </p>
      <li>Family Contact Information</li>
      <li>Account/Registration Information</li>
      <li>Demographic Information</li>
      <li>Your VUET Content</li>
      <li>Online Identifiers</li>
      <li>Internet Activity Information</li>
      <li>Payment Information</li>
      <li>Interests, Preferences, and Other Behavioural Data</li>
      <li>Employment and Professional Information, and Education Information</li>
      <ol>
        <TitledListItem title="Personal Information We Sell to Third Parties" text=""/>
      </ol>
      <p>VUET does not sell your name, e-mail address, or other identifying information. We do work with advertising partners and share the advertising identifier assigned to your device in order for those partners to show ads that are targeted to your interests.</p>
      <p className="mt-4">
        <b>Below are the categories of personal information that we sell to third parties:</b>
      </p>
      <li>Online Identifiers</li>
      <li>Internet Activity Information</li>
      <li>Interests, Preferences, and Other Behavioural Data</li>
      <SubTitle text="III. Your Privacy Rights" />
      <ol>
        <TitledListItem title="What Rights Do You Have" text=""/>
      </ol>
      <TitledListItem title="Shine the Light Rights" text="You have the right to request and obtain from us, once a year and free of charge, a list of the third parties to whom we have disclosed certain types of personal information (if any) for their direct marketing purposes in the prior calendar year. At our option, we may respond to such requests by providing instructions about how our users can exercise their options to prevent our disclosure of personal information to third parties for their direct marketing purposes."/>
      <TitledListItem title="Right to Opt Out of a Sale of Personal Information" text="You have the right to ask us not to sell your personal information to third parties."/>
      <SubTitle text="IV. How Do You Submit These Requests to Us?" />
      <p>In addition to the rights detailed in the “How Can You Control Our Use of Personal Information?” section above, California residents can:</p>
      <li>Contact us to exercise your Shine the Light Rights at info@vuet.app.</li>
      <li>Opt Out of Sales. To opt out of our sharing advertising identifiers with our advertising partners, navigate to your account settings. For the mobile app, enable the “Limit Ad Tracking” setting in your iOS mobile device or the “Opt Out of Ads Personalisation” setting in your Android device. For more information on how to exercise your rights, please see our Terms of Use.</li>

      <SubTitle text="Your European Privacy Rights"/>
      <SubTitle text="I. Our Lawful Basis for Processing Your Personal Information"/>
      <ol>
        <TitledListItem title="We Obtain Your Consent to:" text=""/>
      </ol>
      <TitledListItem title="Personalise your experience" text="on VUET, including the ads displayed to you."/>
      <TitledListItem title="Create and manage your account, and provide the features of VUET to you" text="including your calendars, shopping list, to-do list and recipes."/>
      <TitledListItem title="Provide You with third-party offers" text=""/>
      <ol>
        <TitledListItem title="We Rely on Our Legitimate Interests to:" text=""/>
      </ol>
      <TitledListItem title="Analyse and improve our services" text="including repairing errors or debugging."/>
      <TitledListItem title="Measure and analyse visitor traffic and usage patterns" text="including improving the quality and effectiveness of VUET, features, and related communications."/>
      <TitledListItem title="Security and fraud prevention" text=""/>
      <TitledListItem title="Enforce our legal rights or comply with the law." text=""/>
      <ol>
        <TitledListItem title="We Rely on Our Contractual Necessity to Process Your Orders (for example, if you sign up for a Premium account)." text=""/>
      </ol>
      <SubTitle text="II. Your Rights"/>
      <ol>
        <TitledListItem title="In addition to the rights described in this privacy statement, our European visitors also have the right to:" text=""/>
      </ol>
      <TitledListItem title="Withdraw Consent or Object to Processing:" text="Where you have given us consent to use your personal information for any of the above purposes, you have the right to withdraw that consent at any time. You may also have a right to object to our use of your personal information where we are relying on a legitimate interest and there is something about your particular situation that makes you want to object to our processing on this ground."/>
      <TitledListItem title="Restrict Processing:" text="You have the right in some circumstances to request that we restrict certain processing activities relating to your personal information. For example, if you are challenging the accuracy of the personal information we have about you, you can request that we restrict the processing until it is corrected."/>
      <TitledListItem title="Review of Automated Decision Making:" text="To the extent that we use any automated decision making on the platform, you have the right to not be subject to a decision that would result in significant or legal effects without human intervention."/>
      <p className="mt-4">If you are in Europe and would like to exercise any of these rights, please contact us.</p>

      <SubTitle text="How Can You Learn More?" />
      <p className="mb-4">If you have any questions, comments, or concerns about this Privacy Statement, please contact us at info@vuet.app.</p>
      <p className="mb-4">Postal Mail Address:</p>
      <p><b>VUET</b></p>
      <p>199 Uxbridge Road, Hampton Hill, Middlesex, TW12 1AU, UNITED KINGDOM</p>
      <p>Attention: Privacy Office</p>
      <p className="mt-8">Updated 14/5/2024</p>
    </div>
  </div>;
}