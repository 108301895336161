import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import useGetUserDetailsForToken from "../../hooks/useGetuserDetailsForToken";
import { logOut } from "../../reduxStore/slices/auth/actions";

export default function NavBar() {
  const dispatch = useDispatch();
  const { data: user } = useGetUserDetailsForToken();

  return (
    <div className='flex flex-row w-full border-b-2 justify-between shadow-md'>
      <div className='flex flex-row p-4'>
        <Link to="/"><div className="p-4 hover:bg-gray-100 rounded-lg text-lg">Home</div></Link>
        <Link to="/terms"><div className="p-4 hover:bg-gray-100 rounded-lg text-lg">Terms</div></Link>
        <Link to="/privacy-policy"><div className="p-4 hover:bg-gray-100 rounded-lg text-lg">Privacy Policy</div></Link>
        {
          user && <Link to="/account"><div className="p-4 hover:bg-gray-100 rounded-lg text-lg">Account</div></Link>
        }
        {
          !user && <Link to="/login"><div className="p-4 hover:bg-gray-100 rounded-lg text-lg">Log In</div></Link>
        }
      </div>
      <div className="p-4">
        {user && <button
          onClick={() => {
            dispatch(logOut());
          }}
          className="p-4 text-lg hover:bg-gray-100 rounded-lg"
        >Log Out</button>}
      </div>
    </div>
  );
}
