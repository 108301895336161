import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CombinedState, configureStore } from "@reduxjs/toolkit";
import { persistReducer,persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";

import AuthRoute from "./components/layouts/AuthRoute";
import FullPageSpinner from "./components/molecules/FullPageSpinner";
import NavBar from "./components/organisms/NavBar";
import Account from "./components/pages/Account";
import ForgotPassword from "./components/pages/ForgotPassword";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import useFont from "./hooks/useFont";
import { RootAction } from "./reduxStore/actions";
import reducer, { EntireState } from "./reduxStore/reducers";
import { vuetApi } from "./reduxStore/services/api/api";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [ vuetApi.reducerPath ],
  stateReconciler: autoMergeLevel2
};

const pReducer = persistReducer<CombinedState<EntireState>, RootAction>(
  persistConfig,
  reducer as any
);


const store = configureStore({
  reducer: pReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(vuetApi.middleware)
});
const persistor = persistStore(store);

function App() {
  const fontLoaded = useFont("Poppins");

  if (!fontLoaded) {
    return <FullPageSpinner/>;
  }

  return (
    <div className="App font-poppins">
      <Provider store={store}>
        <PersistGate loading={<FullPageSpinner />} persistor={persistor}>
          <BrowserRouter>
            <NavBar />
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="terms" element={<Terms />} />
              <Route path="privacy-policy" element={<Privacy />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route element={<AuthRoute />}>
                <Route path="/account" element={<Account />}></Route>
              </Route>
              <Route path="*" element={<Home />} />
            </Routes>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              className="xs:!bottom-4"
              bodyClassName="font-lato"
              pauseOnFocusLoss
              pauseOnHover
            />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
