import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as EmailValidator from "email-validator";

import { LoginRequest, useLoginWithUsernameAndPasswordMutation } from "../../reduxStore/services/api/users";
import { setAccessToken, setRefreshToken } from "../../reduxStore/slices/auth/actions";
import Button from "../atoms/Button";
import PhoneNumberInput from "../atoms/PhoneNumberInput";
import InputPair from "../molecules/InputPair";
import TextButton from "../molecules/TextButton";

export default function Login() {
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ useEmail, setUseEmail ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ logIn ] = useLoginWithUsernameAndPasswordMutation();

  useEffect(() => {
    document.title = "Vuet | Login";
  }, []);

  return <div className={"flex flex-col items-center w-full"}>
    <div className="flex flex-col items-start max-w-full pt-4">
      {
        useEmail
          ? <InputPair
            value={username}
            label="Email"
            labelClassName="w-32 text-left"
            inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
            placeholder="Email Address"
            onChange={e => {
              setUsername(e.currentTarget.value);
            }}
            className="sm:!items-center max-w-full"
            data-test-id="log-in-username-input"
          />
          : <div className="flex flex-col sm:flex-row justify-start sm:items-center">
            <div className="w-32 text-left">Phone Number</div>
            <PhoneNumberInput
              value={username}
              onChange={phoneNumber => {
                if (phoneNumber) {
                  setUsername(phoneNumber);
                }
              }}
            />
          </div>
      }
      <InputPair
        type="password"
        value={password}
        label="Password"
        labelClassName="w-32 text-left"
        inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
        placeholder="Password"
        onChange={e => {
          setPassword(e.currentTarget.value);
        }}
        className="mt-2 sm:!items-center max-w-full"
        data-test-id="log-in-password-input"
      />
      <div className="flex flex-row justify-between w-full mt-2">
        <TextButton onClick={() => setUseEmail(!useEmail)} text={
          useEmail
            ? "Use Phone Number"
            : "Use Email"
        } />
        <TextButton onClick={() => navigate("/forgot-password")} text="Forgot password" />
      </div>
      <div className="flex flex-row mt-4 items-center">
        <Button
          className="py-1"
          disabled={!password || (useEmail && !EmailValidator.validate(username)) || !username}
          onClick={async e => {
            e.preventDefault();
            try {
              const loginBody: LoginRequest = {
                password
              };

              if (useEmail) {
                loginBody.email = username;
              } else {
                loginBody.phone_number = username;
              }
              const res = await logIn(loginBody).unwrap();

              if (res.refresh) {
                dispatch(setAccessToken(res.access));
                dispatch(setRefreshToken(res.refresh));
              }

              navigate("/account");
            } catch (e) {
              console.error(e);
              toast.error("An error occurred trying to log in. Please try again later");
            }
            return false;
          }}
          data-test-id="log-in-submit-btn"
        >Log in</Button>
      </div>
    </div>
  </div>;
}