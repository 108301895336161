import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { useGetUserDetailsQuery } from "../../reduxStore/services/api/users";
import { selectAccessToken } from "../../reduxStore/slices/auth/selectors";
import FullPageSpinner from "../molecules/FullPageSpinner";
import Login from "../pages/Login";


const AuthRoute = () => {
  const accessToken = useSelector(selectAccessToken);
  const { data: user, isLoading: isLoadingUser } = useGetUserDetailsQuery(accessToken);
  const isLoading = isLoadingUser;

  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (!(accessToken && user)) {
    return <Login />;
  }

  return <>
    <Outlet />
  </>;
};

export default AuthRoute;
