import PhoneInput, { type Value } from "react-phone-number-input";

import "react-phone-number-input/style.css";

export type PhoneNumberInputProps = {
  value: string;
  onChange: (val: Value | undefined) => void
}
export default function PhoneNumberInput ({ onChange, value }: PhoneNumberInputProps) {
  return <PhoneInput
    placeholder="Enter phone number"
    defaultCountry="GB"
    onChange={onChange}
    value={value as Value}
    className="border-1 rounded-lg p-2"
  />;
}