import { ButtonProps } from "../../types/dom";

export default function Button(props: ButtonProps) {
  const { className, disabled, ...otherProps } = props;
  return <button
    {...otherProps}
    disabled={disabled}
    className={
      `${className || ""} p-4 py-1 bg-vuetPrimary rounded-lg shadow-md text-lg
      text-white border-2 ${disabled && "bg-opacity-70"}`
    }
  />;
}