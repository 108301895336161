import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VerificationInput from "react-verification-input";
import { validate } from "email-validator";

import { useCreatePasswordResetCodeMutation, useValidatePasswordResetCodeMutation } from "../../reduxStore/services/api/auth";
import { useSecureUpdateUserDetailsMutation } from "../../reduxStore/services/api/users";
import Button from "../atoms/Button";
import PageTitle from "../atoms/PageTitle";
import PhoneNumberInput from "../atoms/PhoneNumberInput";
import Spinner from "../atoms/Spinner";
import InputPair from "../molecules/InputPair";
import TextButton from "../molecules/TextButton";

export default function ForgotPassword () {
  const [ username, setUsername ] = useState<string>("");
  const [ userId, setUserId ] = useState(-1);
  const [ code, setCode ] = useState("");
  const [ newPassword, setNewPassword ] = useState<string>("");
  const [ newPasswordConf, setNewPasswordConf ] = useState<string>("");
  const [ usingEmail, setUsingEmail ] = useState<boolean>(false);
  const [ step, setStep ] = useState("USERNAME");

  const [ createPasswordResetCode, createPasswordResetCodeResult ] =
    useCreatePasswordResetCodeMutation();

  const [ validatePasswordResetCode ] = useValidatePasswordResetCodeMutation();

  const [ updateUserDetails, updateUserDetailsResult ] =
    useSecureUpdateUserDetailsMutation();

  const navigate = useNavigate();


  return <div>
    {step === "NEW_PASSWORD" && (
      <div className="flex flex-col items-center">
        <PageTitle text={"Enter your new password below"}/>
        <InputPair
          value={newPassword}
          onChange={e => setNewPassword(e.currentTarget.value)}
          type="password"
          label="Password"
          labelClassName="w-40 text-left"
          inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
        />
        <InputPair
          value={newPasswordConf}
          onChange={e => setNewPasswordConf(e.currentTarget.value)}
          type="password"
          label="Confirm password"
          labelClassName="w-40 text-left"
          inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
        />
        {updateUserDetailsResult.isLoading ? (
          <Spinner />
        ) : (
          <Button
            className="mt-4"
            onClick={async () => {
              try {
                await updateUserDetails({
                  user_id: userId,
                  password: newPassword,
                  reset_password_code: code
                }).unwrap();
                toast.success("Your password was successfully updated");
                navigate("/login");
              } catch (err) {
                toast.error("An error occurred. Please try again later");
              }
            }}
          >Update</Button>
        )}
      </div>
    )}
    {step === "CODE" && (
      <div className="flex flex-col items-center mt-6">
        <div className="mb-4">
          {
            usingEmail
              ? "Please enter the 6-digit code we've sent to your email address"
              : "Please enter the 6-digit code we've sent to your mobile number"
          }
        </div>
        <VerificationInput
          value={code}
          onChange={setCode}
          classNames={{
            characterSelected: "outline-vuetPrimary text-vuetPrimary",
            character: "rounded-lg"
          }}
          autoFocus
        />
        <Button
          className="mt-4"
          onClick={async () => {
            try {
              const body = usingEmail
                ? { email: username, code }
                : { phone_number: username, code };
              const res = await validatePasswordResetCode(body).unwrap();

              setUserId(res.user);
              setStep("NEW_PASSWORD");
            } catch {
              toast.error("An error occurred. Please try again later");
            }
          }}
        >Submit</Button>
      </div>
    )}
    {step === "USERNAME" && (
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-start">
          <PageTitle text={usingEmail ? "Enter email" : "Enter phone number"}/>
          <div>
            {
              usingEmail
                ? <InputPair
                  value={username}
                  label="Email"
                  labelClassName="w-32 text-left"
                  inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
                  placeholder="Email Address"
                  onChange={e => {
                    setUsername(e.currentTarget.value);
                  }}
                  className="sm:!items-center max-w-full"
                  data-test-id="log-in-username-input"
                />
                : <div className="flex flex-col sm:flex-row justify-start sm:items-center">
                  <div className="w-32 text-left">Phone Number</div>
                  <PhoneNumberInput
                    value={username}
                    onChange={phoneNumber => {
                      if (phoneNumber) {
                        setUsername(phoneNumber);
                      }
                    }}
                  />
                </div>
            }
          </div>
          <div className="flex flex-row w-full justify-between mt-2">
            <TextButton text={usingEmail ? "Use Phone": "Use Email"}
              onClick={() => {
                setUsingEmail(!usingEmail);
                setUsername("");
              }}/>
            <TextButton text="Log In"
              onClick={() => navigate("/login")}/>
          </div>
          {createPasswordResetCodeResult.isLoading ? (
            <Spinner />
          ) : (
            <Button
              onClick={() => {
                const body = usingEmail
                  ? { email: username }
                  : { phone_number: username };
                createPasswordResetCode(body)
                  .unwrap()
                  .then(() => {
                    setStep("CODE");
                  })
                  .catch(() => {
                    toast.error("An error occurred. Please try again later");
                  });
              }}
              disabled={
                (usingEmail && !validate(username)) ||
                (!usingEmail && username.length < 10)
              }
              className="mt-4"
            >
            Reset
            </Button>
          )}
        </div>

      </div>
    )}
  </div>;
}