import { Selector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { EntireState } from "../../reducers";

import { AuthState } from "./types";

export const selectAuthState = (state: EntireState): AuthState | undefined =>
  state?.authentication;

export const selectAccessToken: Selector<EntireState, string> = createSelector(
  selectAuthState,
  (auth: AuthState | undefined) => auth?.jwtAccessToken || ""
);

export const selectRefreshToken: Selector<EntireState, string> = createSelector(
  selectAuthState,
  (auth: AuthState | undefined) => auth?.jwtRefreshToken || ""
);
