import { createAction } from "typesafe-actions";

import {
  LOGOUT,
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN } from "./actionNames";

const setAccessToken = createAction(SET_ACCESS_TOKEN)<string>();
const setRefreshToken = createAction(SET_REFRESH_TOKEN)<string>();
const logOut = createAction(LOGOUT)<void>();

export { logOut,setAccessToken, setRefreshToken };
