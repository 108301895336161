import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useGetUserDetailsForToken from "../../hooks/useGetuserDetailsForToken";
import useGetUserFullDetails from "../../hooks/useGetUserFullDetails";
import { useCreateSubscriptionMutation } from "../../reduxStore/services/api/subscriptions";
import Button from "../atoms/Button";

const SubscriptionButton = ({ type, text, isFamily }: { type: "MONTHLY" | "YEARLY"; text: string; isFamily?: boolean; }) => {
  const [ createSubscription, createSubscriptionResult ] = useCreateSubscriptionMutation();
  const { data: userDetails } = useGetUserDetailsForToken();

  if (!userDetails) {
    return null;
  }

  return <Button
    className="p-4 text-lg"
    onClick={async () => {
      try {
        const res = await createSubscription({
          type,
          userId: userDetails.user_id,
          isFamily: !!isFamily
        }).unwrap();
        window.location.href = res.url;
      } catch (err) {
        toast.error("An error occurred trying to subscribe. Please try again later");
      }
    }}
    disabled={createSubscriptionResult.isLoading}
  >
    {text}
  </Button>;
};

export default function Account() {
  const { data: user } = useGetUserFullDetails();

  if (!user) {
    return null;
  }

  return <div className="mt-8">
    <h1 className="text-xl">Welcome, {user.first_name}</h1>
    {
      user.is_premium
        ? <div>
          <div>
            You are currently subscribed to Vuet Premium
          </div>
          <Button className="mt-4">
            <Link to={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL || ""} target="_blank">View Subscription</Link>
          </Button>
        </div>
        : <div>
          <div>
            You are currently a Vuet Basic user.
          </div>
          <div>
            <div className="flex flex-col items-center mt-4">
              <div className="flex flex-col">
                <SubscriptionButton
                  type="MONTHLY"
                  text="Monthly (£1.99)"
                />
                <SubscriptionButton
                  type="YEARLY"
                  text="Yearly (£19)"
                />
                <SubscriptionButton
                  type="MONTHLY"
                  text="Family Monthly (£4.99)"
                  isFamily={true}
                />
                <SubscriptionButton
                  type="YEARLY"
                  text="Family Yearly (£49)"
                  isFamily={true}
                />
              </div>
            </div>
          </div>
        </div>
    }
  </div>;
}